.td-left-align {
    padding-left: 0 !important;
}

.deprecated {
    color: #cc1b1b;
    font-variant: small-caps;
}

.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.sort-arrow {
    display: inline-block;
    font-size: 10pt;
    width: 1.1em;
}

.th-click {
    cursor: pointer;
    white-space: nowrap;
}

.card-image {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
}

.card-container {
    display: inline-block;
    padding: 1em;
}

.just-black {
    color: rgb(74, 74, 74) !important;
}

.first-label {
    padding-right: 2em;
    margin-bottom: 0.5em;
}

.td-number {
    padding-right: 1.5em !important;
    text-align: right !important;
    font-variant-numeric: tabular-nums;
}
